import cover from './cover_rural_android.jpeg';
import './App.css';
import Buttons from './buttons';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Dash">
        <img src={cover} className="App-logo" alt="logo" />
            <Buttons to='https://open.spotify.com/album/75juWn97Ijh2jT1uSADySE' child='Spotify'></Buttons>
            <Buttons to='https://music.apple.com/us/album/rural-android/1781550538?uo=4' child='Apple Music'></Buttons>
            <Buttons to='https://www.youtube.com/watch?v=WeI-VRCYHwU&list=PLmY8j18N5c9lSMBu5dojFZ6U8mOAIne7J' child='YouTube'></Buttons>
            <Buttons to='https://music.apple.com/us/album/rural-android/1781550538?uo=4&app=itunes&at=1001lry3&ct=dashboard' child='iTunes'></Buttons>
            <Buttons to='https://www.instagram.com/olivercarter.ca/' child='Instagram'></Buttons>
            <Buttons to='mailto:olivercarterbookings@gmail.com/' child='olivercarterbookings@gmail.com'></Buttons>
        </div>
      </header>
    </div>
  );
}

export default App;
