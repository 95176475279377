// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  /* background-image: url("./olivercarter_promo1.png"); */
  background-color: grey;
  background-size:  100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-color: white;
  border-style: outset;
  border-radius: 8px;
}

/* @media (prefers-reduced-motion: no-preference) { */
/*   .App-logo { */
/*     animation: App-logo-spin infinite 20s linear; */
/*   } */
/* } */

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a{
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wDAAwD;EACxD,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA,qDAAqD;AACrD,kBAAkB;AAClB,sDAAsD;AACtD,QAAQ;AACR,MAAM;;AAEN;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".App {\n  text-align: center;\n  /* background-image: url(\"./olivercarter_promo1.png\"); */\n  background-color: grey;\n  background-size:  100%;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n  border-color: white;\n  border-style: outset;\n  border-radius: 8px;\n}\n\n/* @media (prefers-reduced-motion: no-preference) { */\n/*   .App-logo { */\n/*     animation: App-logo-spin infinite 20s linear; */\n/*   } */\n/* } */\n\n.App-header {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\na{\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
